import React, { useState, createContext, useContext, useEffect } from 'react';
import { auth } from '../firebase/config';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { useNavigate } from 'react-router';

const AuthContext = createContext();

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  // LOGIN 
  const login = async (loginEmail, loginPassword) => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      setError('');
      navigate('/');
    } catch (error) {
      setError('Invalid username or password');
      console.log(error.message);
    }
  };

  // SIGN UP
  // const register = async (registerEmail, registerPassword) => {
  //   try {
  //     const user = await createUserWithEmailAndPassword(
  //       auth,
  //       registerEmail,
  //       registerPassword
  //     );
  //     console.log(user);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // LOGOUT
  const logout = async () => {
    await signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return unsubscribe;
  }, [])

  const value = {
    user,
    error,
    login,
    logout,
    // register,
  }

  return (
    <>
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    </>
  )
}

export { AuthContext, AuthProvider, useAuth };