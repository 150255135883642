import React from 'react';
import { Box } from '@mui/material';

const Footer = () => {
  return (
    <Box textAlign='center' pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 0 }} style={{ color: 'black', fontFamily: 'Dancing Script', fontWeight: 'bold', fontSize: '20px' }}>
        AB Photo &copy; 2022
    </Box>
  )
}

export default Footer;