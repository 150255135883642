import React, { useState, useEffect } from 'react';
import { 
    Grid, 
    Container, 
    Button,
    Paper,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router';

// Assets
import logo from '../assets/logo.png';
import adventure from '../assets/point-lobos.jpg';
import family from '../assets/Family.jpg';

const HomeScreen = () => {

    useEffect(() => {
        handleResize()
    }, [])

    const navigate = useNavigate();

    const navToFamilyPhotos = () => {
        navigate('/family');
    }

    const navToAdventurePhotos = () => {
        navigate('/adventure');
    }

    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    return (
        <>
            <Grid container direction='column' item xs={12} sm={12} md={7} lg={7}>
                <Grid 
                    item
                    xs
                    sx={{ display: "flex",
                    justifyContent: "flex-start"}}
                >
                    <Container sx={isMobile ? mobileLogo : { pr: 5, mb: 30 }}>
                        <img style={logoStyle} src={logo} alt={logo}/>
                    </Container>
                </Grid>
            </Grid>
            <Paper sx={{ p: 4, mt: 10 }} style={ imageCard }>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Container>
                            <Button onClick={navToAdventurePhotos}>
                                <img style={ adventureStyle } src={adventure} alt={adventure}/>
                            </Button>
                            <Typography sx={{ color: '#fff', fontSize: '30px', fontFamily: 'Dancing Script', fontWeight: 'bold', pt: 3 }}>
                                Adventures
                            </Typography>
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Container>
                            <Button onClick={navToFamilyPhotos} sx={{ color: '#fff', fontSize: '20px', fontFamily: 'Dancing Script', fontWeight: 'bold' }}>
                                <img style={ image } src={family} alt={family}/>
                            </Button>
                            <Typography sx={{ color: '#fff', fontSize: '30px', fontFamily: 'Dancing Script', fontWeight: 'bold', pt: 3 }}>
                                Family
                            </Typography>
                        </Container>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

const logoStyle = {
    height: '100%',
    width: '100%',
}

const image = {
    height: '90%',
    width: '90%',
    boxShadow: '6px 6px 7px black',
}

const adventureStyle = {
    height: '100%',
    width: '100%',
    boxShadow: '6px 6px 7px black',
}

const mobileLogo = {
    pr: 5,
    mt: 20, 
    mb: 50,
}

const imageCard = {
    pb: 6,
    m: 6, 
    backgroundColor: 'rgba(64, 64, 64, 0.8)', 
    color: 'black',
    boxShadow: '3px 3px 8px black'
}


export default HomeScreen;