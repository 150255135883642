import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
//   apiKey: process.env.API_KEY,
//   authDomain: process.env.AUTH_DOMAIN,
//   projectId: process.env.PROJECT_ID,
//   storageBucket: process.env.STORAGE_BUCKET,
//   messagingSenderId: process.env.MESSAGING_SENDER_ID,
//   appId: process.env.APP_ID

    apiKey: "AIzaSyBdmdOSHFv93snhjyqJFpcgd-DaHqy74Tg",
    authDomain: "ab-photo-abf5e.firebaseapp.com",
    projectId: "ab-photo-abf5e",
    storageBucket: "ab-photo-abf5e.appspot.com",
    messagingSenderId: "797231458968",
    appId: "1:797231458968:web:9a557c73f3823c02e47eed"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { storage, app, db, auth };