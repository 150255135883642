import React, { useState, useEffect } from 'react';
import {
    Button,
    List,
    TextField,
    Grid,
    Paper,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Typography,
} from '@mui/material';
import { 
    collection,
    getDocs, 
    addDoc, 
    updateDoc, 
    deleteDoc, 
    doc,
    query,
    where, 
} from '@firebase/firestore';
import { v4 } from 'uuid';
import DeleteIcon  from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../context/AuthProvider';
import { styled } from '@mui/material/styles';

// DB
import { db } from '../firebase/config';

// Components
import Video from '../components/Video';

const VideoGallery = () => {
    const [videos, setVideos] = useState([]);
    const [url, setUrl] = useState('');
    const [category, setCategory] = useState('');
    const [categorySelector, setCategorySelector] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');

    const videosRef = collection(db, 'videos');

    const { user } = useAuth();

    // ADD NEW VIDEO
    const addNewUrl = async () => {
        setLoading(true);
        try {
            await addDoc(videosRef, {
                url: url,
                category: category,
                description: description,
            })
            setLoading(false);
            setError('');
        } catch (err) {
            setLoading(false);
            setError('Error adding video.')
            console.log(err);
        }
        setUrl('');
        setCategory('');
        setDescription('');
        getVideos();
    }

    // EDIT VIDEO DESCRIPTION
    const editDescription = async (id) => {
        const videoDoc = doc(db, 'videos', id);
        const newDescription = { description: description };
        await updateDoc(videoDoc, newDescription);
        getVideos();
    }

    // DELETE VIDEO
    const deleteVideo = async (id) => {
        const videoDoc = doc(db, 'videos', id);
        await deleteDoc(videoDoc, id);
        getVideos();
    }

    // GET ALL VIDEOS
    const getVideos = async () => {
        setLoading(true);
        try {
            const data = await getDocs(videosRef);
            setVideos(data.docs.map((doc) => ({
                // return 'category', 'url', and 'timeStamp' of video
                ...doc.data(),
                // return id
                id: doc.id
            })))
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    // FILTER VIDEOS BY CATEGORY
    const selectorHandler = async (e) => {
        setLoading(true);

        setCategorySelector(e);
        const categoryArray = [];
        if(e === 'all') {
            return getVideos();
        }
        try {
            const q = query(videosRef, where('category', '==', e));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                categoryArray.push(doc.data());
            });
            setError('');
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(`Error loading ${e} videos.`);
            setLoading(false);
        }
        return setVideos(categoryArray);
    }

    useEffect( () => {
        setLoading(true);
        getVideos();
        setLoading(false);
    }, [])

    return (
        <>
        {loading ? 
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <CircularProgress />
            </Grid> 
            : 
            <>
            {error &&  
                <Typography color='error' variant="h6" component="div">
                    {error}
                </Typography>
            }
            {/* ADD VIDEO FORM */}
            {user && 
                <Paper className='videoPaper' sx={{ p: 3, mt: 10 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid spacing={2}  
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                        >
                            <Grid item item xs={12} sm={12} md={6} lg={6}>
                                <TextField 
                                    type='url' 
                                    fullWidth
                                    required
                                    label='Add URL'
                                    placeholder='Enter URL'
                                    onChange={(e) => setUrl(e.target.value)}
                                ></TextField>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        fullWidth
                                        value={category}
                                        label="Category"
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <MenuItem value='family' >Home Videos</MenuItem>
                                        <MenuItem value='grandKids'>Grand Children</MenuItem>
                                        <MenuItem value='adventure'>Adventures</MenuItem>
                                        <MenuItem value='misc'>Misc.</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} lg={10}>
                                <TextField
                                    type='text' 
                                    fullWidth
                                    required
                                    label='Add Description'
                                    placeholder='Description'
                                    onChange={(e) => setDescription(e.target.value)}
                                >   
                                </TextField>
                            </Grid>
                            <Grid item item xs={12} sm={12} md={2} lg={2}>
                                <BootstrapButton  variant='contained' onClick={addNewUrl}>Add Video</BootstrapButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            }
            {/* CATEGORY SELECTOR */}
            <Grid sx={{ pt: 3 }} spacing={2} container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                        <Select
                            fullWidth
                            value={categorySelector}
                            label="Category"
                            onChange={(e) => selectorHandler(e.target.value)}
                        >
                            <MenuItem value='all'>All</MenuItem>
                            <MenuItem value='family'>Home Videos</MenuItem>
                            <MenuItem value='grandKids'>Grand Children</MenuItem>
                            <MenuItem value='adventure'>Adventures</MenuItem>
                            <MenuItem value='misc'>Misc.</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> 
            </Grid>

            {/* VIDEO LIST */}
            {loading ? 
               <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <CircularProgress />
                </Grid> :
                 <List>
                    {videos.map((video) => {
                        return (
                            <>
                                <Video key={video.name + v4()} video={video} />
                                {user && 
                                    <Paper sx={{ p: 3, m: 3 }}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Grid spacing={2}  
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                            >
                                            <Grid item xs={12} sm={12} md={10} lg={10}>
                                                <TextField 
                                                    type='text' 
                                                    placeholder='New Description ... '
                                                    fullWidth
                                                    required
                                                    label='New Description'
                                                    onChange={(e) => setDescription(e.target.value)}
                                                ></TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2} lg={2}>
                                                <Button onClick={() => editDescription(video.id)}>
                                                    <EditIcon/> Save
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Button color='error' onClick={() => deleteVideo(video.id)}>
                                                    <DeleteIcon/> Delete Video
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                             }
                         </>
                     )
                 })}
             </List>
            }
        </>
        }
    </>
    )
}

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#9370DB',
    '&:hover': {
      backgroundColor: '#9932CC',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '	#9932CC',
    },
  });

export default VideoGallery