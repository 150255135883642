import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Assets
import logo from '../assets/logo.png';

const AuthScreen = () => {
  // DEPRECATED REGISTER FUNCTIONALITY

  // const [registerEmail, setRegisterEmail] = useState("");
  // const [registerPassword, setRegisterPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const { error, login } = useAuth();

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
      if (window.innerWidth < 720) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
  }

  useEffect(() => {
      handleResize();
  }, [])



  return (
    <>
        {/* <div>
          <h3> Register User </h3>
          <input
              type='email'
              placeholder="Email..."
              onChange={(event) => {
                  setRegisterEmail(event.target.value);
              }}
          />
          <input
              type='password'
              placeholder="Password..."
              onChange={(event) => {
                  setRegisterPassword(event.target.value);
              }}
          />

          <button onClick={() => register(registerEmail, registerPassword)}> Create User</button>
        </div> */}

        {/* ERROR HANDLING */}
        {error && 
          <Typography color='error' variant="h6" component="div">
            {error}
          </Typography>
        }
        <Grid container item sx={isMobile ? { mt: 15 } : { mt: 5 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper elevation={10} style={isMobile ? paperStyleMobile : paperStyle}>
              <Grid align='center'>
                <img style={logoStyle} src={logo} alt={logo}/>
              </Grid>
              <TextField 
                sx={{ pb: 3 }} 
                label='Email' 
                placeholder='Enter Email' 
                fullWidth required
                onChange={(event) => {
                  setLoginEmail(event.target.value);
                }}
              />
              <TextField 
                type='password' 
                sx={{ pb: 3 }} 
                label='Password' 
                placeholder='Enter Password' 
                fullWidth 
                required
                onChange={(event) => {
                  setLoginPassword(event.target.value);
                }}  
              />
              <BootstrapButton type='submit' variant='contained' onClick={() => login(loginEmail, loginPassword)}> Login</BootstrapButton>
            </Paper>
          </Grid>
        </Grid>
      </>
  );
}

const paperStyle = {
  padding: 20,
  height: '65vh',
  width: 400,
  margin: '20px auto',
  backgroundColor: 'white',
  color: 'white'
}

const paperStyleMobile = {
  padding: 20,
  height: '50vh',
  width: 200,
  margin: '20px auto',
  backgroundColor: 'white',
  color: 'white'
}

const logoStyle = {
  height: '85%',
  width: '85%',
}

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#9370DB',
  '&:hover': {
    backgroundColor: '#9932CC',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '	#9932CC',
  },
});

export default AuthScreen;