import React from 'react';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// Screens
import PhotoGallery from './screens/PhotoGallery';
import FamilyPhotoGallery from './screens/FamilyPhotoGallery';
import VideoGallery from './screens/VideoGallery';
import AuthScreen from './screens/AuthScreen';
import HomeScreen from './screens/HomeScreen';

// Components
import NavBar from './components/NavBar';
import Footer from './components/Footer';

const App = () => {

  return ( 
      <Router>
        <AuthProvider>
          <NavBar/>
          <div style={{ textAlign: 'center', padding: 30, }}>
              <Routes>
                <Route exact path='/' element={<HomeScreen/>}/>
                <Route exact path='/adventure' element={<PhotoGallery/>}/>
                <Route exact path='/family' element={<FamilyPhotoGallery/>}/>
                <Route path='/login' element={<AuthScreen/>}/>
                <Route path='/videos' element={<VideoGallery/>}/>
              </Routes>
          </div>
          <Footer/>
        </AuthProvider>
      </Router>
  )
}

export default App;