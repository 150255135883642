import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper } from '@mui/material';

const Video = ({ video }) => {
    let watchToEmbed = video.url.replace('watch', 'embed/');
    let url = watchToEmbed.replace('?v=', '');

    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        handleResize();
    }, [])


    return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid 
                    spacing={2}  
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Paper sx={videoCard}>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <iframe 
                                width={isMobile ? "150" : "560"} 
                                height={isMobile ? "100" : "315"} 
                                src={url} 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen
                            >
                            </iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={videoDescription}>
                            {video.description}
                        </Grid> 
                    </Paper>
                </Grid>  
            </Box>
    )
}

const videoCard = {
    pb: 6,
    m: 6, 
    backgroundColor: 'rgba(64, 64, 64, 0.8)', 
    color: 'white',
    boxShadow: '5px 5px 9px black'
}

const videoDescription = {
    fontSize: '13px',
    p: 4,
    fontFamily: 'poppins'
}

export default Video;