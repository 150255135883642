
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useScrollTrigger, Slide } from '@mui/material';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router';
import { useAuth } from '../context/AuthProvider';

const drawerWidth = 240;
const navItems = ['Home','Adventure', 'Family', 'Videos', 'Logout'];

function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const { user, logout } = useAuth();

    // NAVIGATION
    const navigate = useNavigate();

    const navToHome = () => {
        navigate('/');
    }

    const navToFamily = () => {
        navigate('/family');
    }
    
    const navToAdventure = () => {
        navigate('/adventure');
    }

    const navToAdminLogin = () => {
        navigate('/login');
    }

    const navToVideos = () => {
        navigate('/videos');
    }

    const logoutAdmin = () => {
        logout();
    }

    const HideOnScroll = (props) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
        {children}
        </Slide>
    );
    }

    HideOnScroll.propTypes = {
        children: PropTypes.element.isRequired,
        window: PropTypes.func,
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: 'white'  }}>
        <Button onClick={navToAdminLogin} sx={{ fontSize: '15px', color: '#9370DB', backgroundColor: 'white' }}>
            AB Photo Adventures {user && <>- Admin</>}
        </Button>
        <Divider/>
        <List sx={{ backgroundColor: 'white', color: '#9370DB'}}>
            <ListItem disablePadding>
                <ListItemButton onClick={navToHome} sx={{ textAlign: 'center' }}>
                    <ListItemText primary={navItems[0]} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={navToAdventure} sx={{ textAlign: 'center' }}>
                    <ListItemText primary={navItems[1]} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={navToFamily} sx={{ textAlign: 'center' }}>
                    <ListItemText primary={navItems[2]} />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={navToVideos} sx={{ textAlign: 'center' }}>
                    <ListItemText primary={navItems[3]} />
                </ListItemButton>
            </ListItem>
            {user &&
            <ListItem disablePadding>
                <ListItemButton onClick={logout} sx={{ textAlign: 'center' }}>
                    <ListItemText primary={navItems[4]} />
                </ListItemButton>
            </ListItem>
            }
        </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <HideOnScroll {...props}>
                <AppBar component="nav" style={{ background: 'transparent', boxShadow: 'none' }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon style={{ color: '#9370DB' }}/>
                        </IconButton>

                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            <Button onClick={navToAdminLogin} sx={{ color: '#fff', fontSize: '20px' }} style={ textStyle }>
                               AB Photo Adventures {user && <>- Admin</>}
                            </Button>
                        </Typography>

                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Button onClick={navToHome} style={ textStyle }>
                                {navItems[0]}
                            </Button>
                            <Button onClick={navToAdventure} style={ textStyle }>
                                {navItems[1]}
                            </Button>
                            <Button onClick={navToFamily} style={ textStyle }>
                                {navItems[2]}
                            </Button>
                            <Button onClick={navToVideos} sx={ textStyle }>
                                {navItems[3]}
                            </Button>
                            {user &&  
                            <Button onClick={logoutAdmin} sx={ textStyle }>
                                Logout
                            </Button>
                            }
                        </Box>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
        <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
        </Box>
        </Box>
    );
}

 const textStyle = {
    color: 'black',
    fontSize: '17px',
    fontWeight: 'bold',
    fontFamily: 'Dancing Script'
}

export default DrawerAppBar;