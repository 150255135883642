import React, { useState, useEffect } from 'react';
import { 
    Button, 
    Container, 
    ImageList,
    CircularProgress,
    Grid,
    Typography,
} from '@mui/material';
import { storage } from '../firebase/config';
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import { useAuth } from '../context/AuthProvider';
import { styled } from '@mui/material/styles';

// Components
import Image from '../components/Image';

const PhotoGallery = () => {

    const { user } = useAuth();

    // STATE
    const [imageUpload, setImageUpload] = useState(null);
    const [imageList, setImageList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const imageListRef = ref(storage, 'images/');

    const uploadImage = () => {
        setError('');
        setLoading(true);
        if(imageUpload === null) {
            setLoading(false);
            return;
        }
        const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        if (imageUpload && (imageUpload.name.includes('jpg') ||  imageUpload.name.includes('jpeg') || imageUpload.name.includes('png'))) {
            uploadBytes(imageRef, imageUpload)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setImageList((prev) => 
                        [...prev, {url: url, path: snapshot.metadata.fullPath }]
                    )
                })
                setError('');
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                setError('Error uploading image');
                setLoading(false);
            });
        } else {
            setError('Wrong file type. Only jpeg, jpg, png');
            setLoading(false);
        }
    }

    useEffect(() => {
        setError('');
        setLoading(true);
        listAll(imageListRef)
        .then((res) => {
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prevList) => 
                    [...prevList, {url: url, path: item.fullPath}]
                )
                }).catch((err) => {
                    setError('Bandwidth exceeded.')
                    console.log(err);
                })
            })
        }).catch((err) => {
            setError('Bandwidth exceeded.')
            console.log(err)
        })
        setError('');
        setLoading(false);
    }, [])

    const refreshAfterDelete = () => {
        setError('');
        setLoading(true);
        listAll(imageListRef)
        .then((res) => {
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prevList) => 
                    prevList.splice(-1));
                }).catch((err) => {
                    console.log(err);
                })
            })
            setError('');
            setLoading(false);
        }).catch((err) => {
            setError('Error refreshing photos');
            setLoading(false);
            console.log(err)
        })
    }
    return (
        <>
            {error && 
                <Typography color='error' variant="h6" component="div">
                    {error}
                </Typography>
            }
            {loading ? 
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <CircularProgress />
            </Grid> 
            : 
            <>
                <Container sx={{ mt: 10 }}>
                    { user && 
                        <form>
                            <label>
                                <input type='file' onChange={(e) => setImageUpload(e.target.files[0])}/>
                            </label>
                            <BootstrapButton onClick={uploadImage} variant='contained'>Upload Image</BootstrapButton>
                        </form>}

                    <ImageList
                        gap={12}
                        sx={{ width: 600, height: 550 }}
                        rowHeight={300}
                        sx={{
                            mb: 8, 
                            gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))!important'
                        }}
                    >
                        {imageList.map((image) => (
                            <Image 
                                key={`${image.url + v4()}`} 
                                url={image.url}
                                path={image.path}
                                refreshAfterDelete={refreshAfterDelete}
                            />
                        ))}
                    </ImageList>
                </Container>
            </>
            }
        </>
    )
}

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#9370DB',
    '&:hover': {
      backgroundColor: '#9932CC',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '	#9932CC',
    },
  });

export default PhotoGallery;