import React, { useState, useEffect } from 'react';
import {     
    ImageListItem,
    Modal,
    Box, 
    Button,
    Container,
    Card,
} from '@mui/material';
import  DeleteIcon  from '@mui/icons-material/Delete';
import { useAuth } from '../context/AuthProvider';
import { ref, deleteObject } from 'firebase/storage';
import { storage } from '../firebase/config';
import { v4 } from 'uuid';

const Image = ({ url, path, refreshAfterDelete }) => {

    // Modal Handlers
    const [open, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { user } = useAuth();

    const imageDelete = () => {
        const imageRef = ref(storage, path);
        deleteObject(imageRef).then(() => {
            refreshAfterDelete();
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <Container>
                <Button onClick={handleOpen}>
                    <Card key={`${url + v4()}`} sx={{ boxShadow: '3px 3px 9px black' }}>
                        <ImageListItem>
                            <img
                                src={url}
                                alt={url}
                                srcSet={url}
                                loading="lazy"
                                style={{ cursor: 'pointer' }}
                            />
                        </ImageListItem>
                    </Card>
                </Button>
                {/* Render Delete button if Admin is signed in */}
                {user &&  <Button variant='contained' color='error' onClick={() => imageDelete(url)}>
                    <DeleteIcon/> Delete Photo
                </Button>}

            </Container>
        
            <Modal
                key={`${url + v4()}`}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* <Box sx={boxStyle} component='div'>Test</Box> */}
                <Box sx={boxStyle} style={{ width: isMobile ? '35vh' : 475 }}>
                    <img 
                        style={imgStyle}
                        src={url}
                        alt={url}
                        srcSet={url}
                        loading="lazy"
                    />
                </Box>
            </Modal>
        </>
    )
}

// Modal Style
const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    p: 2,
    backgroundColor:'rgb(232,232,232,0.5)',
    boxShadow: '5px 5px 9px black'
};

const imgStyle = {
    height: '100%',
    width: '100%',
};

export default Image;